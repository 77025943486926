.home {
    background: center / cover url(../../assets/background.png);
    min-height: calc(100vh + var(--header-height));
    display: flex;
    align-items: center;
    position: relative;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(139, 69, 19, 0.5), rgba(139, 69, 19, 0));
    z-index: 1;
}

.home__container {
    width: 100%;
    position: relative;
    z-index: 2;
}

.home__content {
    max-width: 660px;
}

.home__subtitle,
.home__description {
    color: var(--title-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.25px;
    text-align: left;
}

.home__subtitle {
    font-family: var(--second-font);
    font-size: var(--h1-font-size);
    font-weight: var(--weight-500);
}

.home__title {
    font-size: var(--biggest-font-size);
    font-weight: var(--weight-900);
    margin-block: 1.25rem;
}

.home__description {
    margin-bottom: 2.25rem;
}


.home__btns {
    display: flex;
    column-gap: 2rem;
}

.home__btn {
    background: transparent;
}

.home__btn:hover {
    background: var(--first-color);
    color: var(--title-color);
}

@media only screen and (max-width: 992px) {
    .home__btns {
        flex-wrap: wrap;
        gap: 1.25rem;
    }
}

@media only screen and (max-width: 576px) {
    .home__btns {
        flex-wrap: wrap;
        gap: 1.25rem;
    }
}