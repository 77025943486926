.features__grid {
    grid-template-columns: repeat(3, 1fr);
}

.features__item {
    background-color: white;
    padding: 2rem;
    border-radius: var(--radius);
    position: relative;
    height: 450px;
    transition: 0.5s var(--transition);
}

.features__item-reason {
    color: white;
    padding: 2rem;
    border-radius: 25px;
    position: relative;
    height: 450px;
    transition: 0.5s var(--transition);
}

.features__item::before {
    content: '';
    position: absolute;
    inset: 0.75rem;
    border: 2px solid var(--first-color);
    opacity: 0.2;
    border-radius: inherit;
}

.feature__img {
    width: 300px;
    height: 250px;
    margin-bottom: 1.25rem;
}

.feature__title {
    font-size: var(--h3-font-size);
    margin-bottom: 0.625rem;
}

.feature__shape {
    position: absolute;
    width: 120px;
    right: 1.25rem;
    top: 3rem;
    opacity: 0.3;
}

.features__item:hover {
    transform: translateY(-0.5rem);
}

@media only screen and (max-width: 1200px) {
    .features__grid {

        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .features__grid {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 576px) {
    .features__grid {
        grid-template-columns: 1fr;
    }
}