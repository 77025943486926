/*=================================== 
#GOOGLE FONTS 
===================================*/

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600&family=Lobster+Two:wght@400;700&family=Montserrat:wght@400;500;600&display=swap');
/*=================================== 
#CSS VARIABLES
===================================*/

:root {
    --header-height: 5rem;

    /*===============
     * Colors
    ===============*/

    --first-color: hsl(27, 34%, 59%);
    --first-color-light: hsl(42, 13%, 34%);
    --title-color: hsl(0, 0%, 100%);
    --text-color: hsl(0, 0%, 67%);
    --bg-color: hsl(0, 0%, 100%);
    --container-color: hsl(0, 0%, 6%);
    --hsla: hsl(0, 0%, 0%, 0.75);

    /*===============
     * Typography
    ===============*/

    --body-font: 'Montserrat', sans-serif;

    --second-font: 'Lobster Two', cursive;


    --biggest-font-size: clamp(2rem, 1.125rem + 4vw, 4.125rem);
    ;
    --bigger-font-size: clamp(1.875rem, 1.3603rem + 2.3529vw, 3.125rem);
    --big-font-size: clamp(1.75rem, 1.4412rem + 1.4118vw, 2.5rem);
    --h1-font-size: 1.87rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.5rem;
    --largest-font-size: 1.375rem;
    --larger-font-size: 1.25rem;
    --large-font-size: 1.125rem;
    --normal-font-size: 1rem;

    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
    --weight-900: 900;

    /*===============
     * Border-radius
    ===============*/

    --radius: 0.5rem;

    /*===============
     * Transition
    ===============*/

    --transition: ease-in-out;
}

/*=================================== 
#RESET
===================================*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

input,
textarea,
button,
body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

body {
    color: var(--text-color);
    background: var(--bg-color);
}

h1,
h2,
h3 {
    font-family: var(--second-font);
    color: var(--title-color);
    font-weight: var(--weight-400);
}

ul {
    list-style: none;
}

p {
    line-height: 1.8;
}

a {
    text-decoration: none;
}

input,
textarea {
    border: none;
    outline: none;
}

img {
    max-width: 100%;
}



/*=================================== 
#RESUABLE CSS CLASSES
===================================*/

.container {
    max-width: 1300px;
    margin-inline: auto;
    padding-inline: 1rem
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.btn {
    display: inline-block;
    color: var(--title-color);
    background: var(--first-color);
    border: 2px solid var(--first-color);
    padding: 1rem 1.5rem;
    border-radius: var(--radius);
}

.btn:hover {
    background-color: transparent;
    color: var(--first-color);
}

.btn--flex {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}

.section {
    padding-block: 7.5rem
}

.section__title {
    font-size: var(--big-font-size);
    text-align: center;
    margin-bottom: 3rem;
}

.section__title::before {
    content: attr(data-title);
    display: block;
    color: var(--first-color);
    font-family: var(--second-font);
    font-size: var(--h2-font-size);
    letter-spacing: 2px;
    margin-bottom: 0.5rem;
}

.title-left {
    text-align: left;
    margin-bottom: 1rem;
}

/* MEDIA QUERY */

@media only screen and (max-width: 1200px) {
    .container {
        max-width: 960px;
    }
}

@media only screen and (max-width: 992px) {
    :root {
        --h1-font-size: 1.5rem;
        --h2-font-size: 1.375rem;
        --h3-font-size: 1.25rem;
        --largest-font-size: 1.25rem;
        --larger-font-size: 1.125rem;
        --large-font-size: 1rem;
        --normal-font-size: 0.9375rem;
    }

    .container {
        max-width: 720px;
    }

    .btn {
        padding: 0.75rem 1.25rem;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 540px;
    }
}