.footer {
    background-color: #864622;
}

.footer__grid {
    grid-template-columns: 4fr 2fr 3fr 3fr;
    padding-block: 6.25rem 4.5rem;
}

.footer__title,
.footer__logo-img {
    margin-bottom: 2rem;
}

.footer__logo-img {
    width: 190px;
}

.footer__description {
    margin-bottom: 1.25rem;
}

.footer__contact_item {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 1rem;
}

.footer__contact_item .icon {
    color: var(--first-color);
    font-size: var(--larger-font-size);
}

.footer__title {
    font-size: var(--h3-font-size);
    letter-spacing: 1.5px;
    position: relative;
    padding-bottom: 1.25rem;
}

.footer__title::before,
.footer__title::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;

    background-color: var(--first-color);
}

.footer__title::before {
    left: 0;
    width: 12px;
}

.footer__title::after {
    left: 1.125rem;
    width: 30px;
}

.footer__links,
.footer__link {
    display: flex;
}

.footer__links {
    flex-direction: column;
    gap: 1.25rem;
}

.footer__link {
    color: var(--title-color);
    align-items: center;
    column-gap: 0.25rem;
}

.footer__link .icon {
    color: var(--first-color);
}

.footer__opening-hour {
    background-color: var(--bg-color);
    padding: 2rem 1.5rem;
    position: relative;
}

.footer__opening-hour::before {
    content: '';
    position: absolute;
    inset: 0.5rem;
    border: 2px dashed var(--first-color);
}

.opening_hour-item {
    display: flex;
    justify-content: space-between;
}

.opening_hour-item:not(:last-child) {
    margin-bottom: 1.125rem;
}

.subscribe__input {
    border: 2px solid var(--title-color);
}

.subscribe__input::placeholder {
    color: var(--title-color);
}

.subscribe__btn {
    width: 100%;
    justify-content: center;
    margin-top: 1.2rem;
}

.footer__social-follow {
    font-size: var(--normal-font-size);
    margin-block: 1.5rem 1rem;
}

.footer__social-links {
    display: flex;
    column-gap: 1rem;

}

.footer__social-link {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: var(--title-color);
    border: 2px solid var(--first-color);
    display: grid;
    place-items: center;
    transition: 0.5s var(--transition);
}

.footer__social-link:hover {
    background-color: var(--first-color);
}

.copyright__text {
    padding-block: 1.25rem;
    border-top: 2px dashed hsla(0, 0%, 100%, 0.1);
    text-align: center;
    color: var(--title-color);
}

.copyright__text span {
    color: var(--first-color);
}

/* MEDIA QUERY */

@media only screen and (max-width: 1200px) {
    .footer__grid {

        grid-template-columns: repeat(2, 1fr);
    }

    .footer__grid {
        row-gap: 3rem;
    }
}

/* MEDIA QUERY */

@media only screen and (max-width: 992px) {
    .footer__logo-img {
        width: 140px;
    }

}

@media only screen and (max-width: 576px) {
    .footer__grid {
        grid-template-columns: 1fr;
    }
}