.about {
    padding-bottom: 0;
}

.about__grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 3rem;
}

.about__img-wrapper {
    position: relative;
    padding-left: 1.5rem;
}

.about__img-wrapper::before {
    content: '';
    position: absolute;
    bottom: -1.125rem;
    left: 0;
    background-color: var(--first-color);
    width: 100%;
    height: 100%;
    z-index: -1;
}

.about__img {
    width: 100%;
}

.about__img-wrapper-test {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    margin-bottom: 20px;
}


@media (max-width: 768px) {
    .about__img-wrapper-test {
        flex-direction: column;
        align-items: center;
    }

    .about__image-test {
        max-width: 100%;
    }
}

.about__details {
    margin-block: 1.25rem 2.25rem;
}

.about__details-description {
    display: flex;
    align-items: center;
}

.about__details,
.about__details-description {
    gap: 0.625rem;
}

/* MEDIA QUERY */

@media only screen and (max-width: 992px) {
    .about__grid {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 576px) {
    .about__img-wrapper {
        padding-left: 1rem;
    }

    .about__img-wrapper::before {
        bottom: -0.625rem;
    }
}