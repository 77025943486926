.header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 60px;
    display: flex;
    align-items: center;
}

.nav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.nav__logo-img {
    width: 120px;
    cursor: pointer;
}

.nav__list {
    display: flex;
    column-gap: 2rem;
    align-items: center;
}

.nav__link,
.nav__toggle {
    color: var(--title-color);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav__link {
    font-size: var(--large-font-size);
    font-weight: var(--weight-500);
    padding: 0 10px;
}

.nav__toggle {
    font-size: var(--h2-font-size);
    display: none;
}

.scroll-header {
    position: fixed;
    background-color: #864622;
    animation: slide-down 0.7s;
    height: 60px;
    display: flex;
    align-items: center;
}

/* MEDIA QUERY */

@media only screen and (max-width: 992px) {
    .nav__logo-img {
        width: 100px;
    }

    .nav__menu {
        position: absolute;
        top: 100%;
        inset-inline: 0.75rem;
        background-color: var(--first-color);
        max-height: 0;
        overflow: scroll;
        transition: 0.3s var(--transition);
    }

    .show-menu {
        max-height: 250px;
    }

    .nav__list {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav__item {
        padding: 0.5rem 1.25rem;
    }

    .nav__toggle {
        display: block;
    }
}